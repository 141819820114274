/**
 * Generated bundle index. Do not edit.
 */

export * from './public_api';

export {NgxWebstorageConfiguration as Éµm} from './lib/config';
export {WebStorage as Éµk} from './lib/core/interfaces/webStorage';
export {LocalStorageProvider as Éµb,SessionStorageProvider as Éµd,getLocalStorage as Éµa,getSessionStorage as Éµc} from './lib/core/nativeStorage';
export {Services as Éµn} from './lib/services/index';
export {LocalStorageServiceProvider as Éµg,buildService as Éµf} from './lib/services/localStorage';
export {SessionStorageServiceProvider as Éµi,buildService as Éµh} from './lib/services/sessionStorage';
export {STORAGE_STRATEGIES as Éµl} from './lib/strategies';
export {BaseSyncStorageStrategy as Éµj} from './lib/strategies/baseSyncStorage';
export {Strategies as Éµe} from './lib/strategies/index';