import { Component } from '@angular/core';

@Component({
  selector: 'emoji-footer',
  styleUrls: ['../styles/emoji-footer.scss'],
  template: `
  <footer class="emoji-footer"></footer>
  `
})

export class EmojiFooterComponent {
  constructor() { }
}
