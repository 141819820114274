import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, AsyncSubject } from 'rxjs';
import { AS_COMPLETE, BLOB_TO_FILE, CANVAS_TO_BLOB, DRAW_RESULT_APPEND_CHILD, FILES_TO_SCAN, HAS_OWN_PROPERTY, OVERRIDES, PLAY_AUDIO, VIBRATE } from './ngx-scanner-qrcode.helper';
import { ScannerQRCodeConfig, ScannerQRCodeDevice, ScannerQRCodeResult, ScannerQRCodeSelectedFiles } from './ngx-scanner-qrcode.options';
import { CONFIG_DEFAULT, MEDIA_STREAM_DEFAULT } from './ngx-scanner-qrcode.default';
declare const zbarWasm: any;

@Component({
  selector: 'ngx-scanner-qrcode',
  template: `<div #resultsPanel class="origin-overlay"></div><canvas #canvas class="origin-canvas"></canvas><video #video playsinline class="origin-video"></video>`,
  styleUrls: ['./ngx-scanner-qrcode.component.scss'],
  host: { 'class': 'ngx-scanner-qrcode' },
  exportAs: 'scanner',
  inputs: ['src', 'fps', 'vibrate', 'decode', 'isBeep', 'deviceActive', 'config', 'constraints'],
  outputs: ['event'],
  queries: {
    video: new ViewChild('video'),
    canvas: new ViewChild('canvas'),
    resultsPanel: new ViewChild('resultsPanel')
  },
  encapsulation: ViewEncapsulation.None
})
export class NgxScannerQrcodeComponent implements OnInit, OnDestroy {

  /**
   * Element
   * playsinline required to tell iOS safari we don't want fullscreen
   */
  public video!: ElementRef<HTMLVideoElement>;
  public canvas!: ElementRef<HTMLCanvasElement>;
  public resultsPanel!: ElementRef<HTMLDivElement>;

  /**
   * EventEmitter
   */
  public event = new EventEmitter<ScannerQRCodeResult[]>();

  /**
   * Input
   */
  public src: string | undefined = CONFIG_DEFAULT.src;
  public fps: number | undefined = CONFIG_DEFAULT.fps;
  public vibrate: number | undefined = CONFIG_DEFAULT.vibrate;
  public decode: string | undefined = CONFIG_DEFAULT.decode;
  public isBeep: boolean | undefined = CONFIG_DEFAULT.isBeep;
  public deviceActive: number | undefined = CONFIG_DEFAULT.deviceActive;
  public config: ScannerQRCodeConfig = CONFIG_DEFAULT;
  public constraints: MediaStreamConstraints | any | undefined = CONFIG_DEFAULT.constraints;

  /**
   * Export
   */
  public isStart: boolean = false;
  public isLoading: boolean = false;
  public isTorch: boolean = false;
  public data = new BehaviorSubject<ScannerQRCodeResult[]>([]);
  public devices = new BehaviorSubject<ScannerQRCodeDevice[]>([]);

  /**
   * Private
   */
  private rAF_ID: any;
  private dataForResize: ScannerQRCodeResult[] = [];

  ngOnInit(): void {
    this.overrideConfig();
    if (this.src) {
      this.loadImage(this.src);
    }
    this.resize();
  }

  /**
   * start
   * @return AsyncSubject
   */
  public start(): AsyncSubject<any> {
    const as = new AsyncSubject<any>();
    if (this.isStart) {
      // Reject
      AS_COMPLETE(as, false);
    } else {
      // mediaDevices
      this.loadAllDevices(as);
    }
    return as;
  }

  /**
   * playDevice
   * @param deviceId 
   * @param as 
   * @return AsyncSubject 
   */
  public playDevice(deviceId: string, as: AsyncSubject<any> = new AsyncSubject<any>()): AsyncSubject<any> {
    const existDeviceId = this.isStart ? this.getConstraints().deviceId != deviceId : true;
    switch (true) {
      case deviceId == 'null' || deviceId == null:
        this.stop();
        AS_COMPLETE(as, false);
        break;
      case deviceId && existDeviceId:
        stop();
        this.stop();
        clearInterval(this.rAF_ID);
        // Loading on
        this.status(false, true);
        const constraints = {
          audio: false,
          video: (typeof (this.constraints && this.constraints.video) === 'boolean') ? { deviceId: deviceId } : Object.assign({ deviceId: deviceId }, this.constraints && this.constraints.video)
        };
        this.deviceActive = this.devices.value.findIndex(f => f.deviceId == deviceId);
        // MediaStream
        navigator.mediaDevices.getUserMedia(constraints).then((stream: MediaStream) => {
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.onloadedmetadata = () => {
            this.video.nativeElement.play();
            this.requestAnimationFrame();
            this.status(true, false);
            AS_COMPLETE(as, true);
          }
        }).catch(error => {
          this.status(false, false);
          this.eventEmit(false);
          AS_COMPLETE(as, false, error);
        });
        break;
      default:
        AS_COMPLETE(as, false);
        break;
    }
    return as;
  }

  /**
   * stop
   * @return AsyncSubject
   */
  public stop(): AsyncSubject<any> {
    this.eventEmit(null);
    this.status(false, false);
    const as = new AsyncSubject<any>();
    try {
      this.removeCanvas();
      clearInterval(this.rAF_ID);
      (this.video.nativeElement.srcObject as MediaStream).getTracks().forEach((track: any) => {
        track.stop();
        AS_COMPLETE(as, true);
      });
    } catch (error) {
      AS_COMPLETE(as, false, error as any);
    }
    return as;
  }

  /**
   * play
   * @return AsyncSubject
   */
  public play(): AsyncSubject<any> {
    const as = new AsyncSubject<any>();
    if (this.isPause) {
      this.video.nativeElement.play();
      this.requestAnimationFrame();
      AS_COMPLETE(as, true);
    } else {
      AS_COMPLETE(as, false);
    }
    return as;
  }

  /**
   * pause
   * @return AsyncSubject 
   */
  public pause(): AsyncSubject<any> {
    const as = new AsyncSubject<any>();
    if (this.isStart) {
      clearInterval(this.rAF_ID);
      this.video.nativeElement.pause();
      AS_COMPLETE(as, true);
    } else {
      AS_COMPLETE(as, false);
    }
    return as;
  }

  /**
   * loadImage
   * @param src 
   * @return AsyncSubject
   */
  public loadImage(src: string): AsyncSubject<any> {
    const as = new AsyncSubject<any>();
    // Loading on
    this.status(false, true);
    // Set the src of this Image object.
    const image = new Image();
    // Setting cross origin value to anonymous
    image.setAttribute('crossOrigin', 'anonymous');
    // When our image has loaded.
    image.onload = () => {
      this.drawImage(image, (flag: boolean) => {
        this.status(false, false);
        AS_COMPLETE(as, flag);
      });
    };
    // Set src
    image.src = src;
    return as;
  }

  /**
   * torcher
   * @return AsyncSubject
   */
  public torcher(): AsyncSubject<any> {
    const as = this.applyConstraints({ torch: this.isTorch })
    as.subscribe(() => false, () => this.isTorch = !this.isTorch);
    return as;
  }

  /**
   * applyConstraints
   * @return AsyncSubject
   */
  public applyConstraints(constraints: MediaTrackConstraintSet | any): AsyncSubject<any> {
    const as = new AsyncSubject<any>();
    const stream = this.video.nativeElement.srcObject as MediaStream;
    const videoTrack = stream.getVideoTracks()[0] as MediaStreamTrack;
    const imageCapture = new (window as any).ImageCapture(videoTrack);
    imageCapture.getPhotoCapabilities().then(async () => {
      await videoTrack.applyConstraints({ advanced: [constraints] } as MediaTrackConstraints);
      AS_COMPLETE(as, true);
    }).catch((error: any) => {
      switch (error && error.name) {
        case 'NotFoundError':
        case 'DevicesNotFoundError':
          AS_COMPLETE(as, false, 'Required track is missing' as any);
          break;
        case 'NotReadableError':
        case 'TrackStartError':
          AS_COMPLETE(as, false, 'Webcam or mic are already in use' as any);
          break;
        case 'OverconstrainedError':
        case 'ConstraintNotSatisfiedError':
          AS_COMPLETE(as, false, 'Constraints can not be satisfied by avb. devices' as any);
          break;
        case 'NotAllowedError':
        case 'PermissionDeniedError':
          AS_COMPLETE(as, false, 'Permission denied in browser' as any);
          break;
        case 'TypeError':
          AS_COMPLETE(as, false, 'Empty constraints object' as any);
          break;
        default:
          AS_COMPLETE(as, false, error as any);
          break;
      }
    });
    return as;
  };

  /**
   * getConstraints
   * @param deviceActive 
   * @returns 
   */
  public getConstraints(): MediaTrackConstraintSet | any {
    const stream = this.video.nativeElement.srcObject as MediaStream;
    const videoTrack = stream && stream.getVideoTracks()[0] as MediaStreamTrack;
    return videoTrack && videoTrack.getConstraints() as any;
  }

  /**
   * download
   * @param fileName 
   * @return AsyncSubject
   */
  public download(fileName: string = `ngx-scanner-qrcode-${Date.now()}.png`): AsyncSubject<ScannerQRCodeSelectedFiles[]> {
    const as = new AsyncSubject<any>();
    const run = async () => {
      const blob = await CANVAS_TO_BLOB(this.canvas.nativeElement);
      const file = BLOB_TO_FILE(blob, fileName);
      FILES_TO_SCAN([file], this.config, as).subscribe((res: ScannerQRCodeSelectedFiles[]) => {
        res.forEach((item: ScannerQRCodeSelectedFiles) => {
          const link = document.createElement('a');
          link.href = item.url;
          link.download = item.name;
          link.click();
          link.remove()
        });
      });
    }
    run();
    return as;
  }

  /**
   * window: resize
   * Draw again!
   */
  private resize(): void {
    window.addEventListener("resize", () => {
      if (this.dataForResize && this.dataForResize.length) {
        DRAW_RESULT_APPEND_CHILD(this.dataForResize as any, this.canvas.nativeElement, this.resultsPanel.nativeElement);
      }
    });
  }

  /**
   * Override config
   * @return void
   */
  private overrideConfig(): void {
    if (HAS_OWN_PROPERTY(this.config, 'src')) this.src = this.config.src;
    if (HAS_OWN_PROPERTY(this.config, 'fps')) this.fps = this.config.fps;
    if (HAS_OWN_PROPERTY(this.config, 'vibrate')) this.vibrate = this.config.vibrate;
    if (HAS_OWN_PROPERTY(this.config, 'decode')) this.decode = this.config.decode;
    if (HAS_OWN_PROPERTY(this.config, 'isBeep')) this.isBeep = this.config.isBeep;
    if (HAS_OWN_PROPERTY(this.config, 'deviceActive')) this.deviceActive = this.config.deviceActive;
    if (HAS_OWN_PROPERTY(this.config, 'constraints')) this.constraints = OVERRIDES('constraints', this.config, MEDIA_STREAM_DEFAULT);
  }

  /**
   * loadAllDevices
   */
  private loadAllDevices(as: AsyncSubject<any>): void {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      let cameraDevices: ScannerQRCodeDevice[] = [];
      devices.forEach(f => f.kind == 'videoinput' && cameraDevices.push(f));
      this.devices.next(cameraDevices);
      if (cameraDevices.length > 0) {
        const index = Math.max(0, (cameraDevices.length > this.deviceActive ? this.deviceActive : 0));
        this.playDevice(cameraDevices[index].deviceId, as);
      } else {
        AS_COMPLETE(as, false, 'No camera detected.' as any);
      }
    });
  }

  /**
   * drawImage
   * @param element 
   * @param callback 
   */
  private async drawImage(element: HTMLImageElement | HTMLVideoElement, callback: Function = () => { }): Promise<void> {
    // Get the canvas element by using the getElementById method.
    const canvas = this.canvas.nativeElement;
    // Get a 2D drawing context for the canvas.
    const ctx = canvas.getContext('2d', { willReadFrequently: true }) as CanvasRenderingContext2D;
    // HTMLImageElement size
    if (element instanceof HTMLImageElement) {
      canvas.width = element.naturalWidth;
      canvas.height = element.naturalHeight;
      element.style.visibility = '';
      this.video.nativeElement.style.visibility = 'hidden';
      this.video.nativeElement.style.height = canvas.offsetHeight + 'px';
    }
    // HTMLVideoElement size
    if (element instanceof HTMLVideoElement) {
      canvas.width = element.videoWidth;
      canvas.height = element.videoHeight;
      element.style.visibility = '';
      this.canvas.nativeElement.style.visibility = 'hidden';
    }
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    // Draw image
    ctx.drawImage(element, 0, 0, canvas.width, canvas.height);
    // Data image
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    // Draw frame
    const code = await zbarWasm.scanImageData(imageData);
    if (code && code.length) {
      // Decode
      code.forEach((s: any) => s.value = s.decode(this.decode && this.decode.toLocaleLowerCase()));

      // Overlay
      DRAW_RESULT_APPEND_CHILD(code, Object.freeze(this.canvas.nativeElement), this.resultsPanel.nativeElement);

      // To blob and emit data
      const EMIT_DATA = () => {
        this.eventEmit(code);
        this.dataForResize = code;
      };

      // HTMLImageElement
      if (element instanceof HTMLImageElement) {
        callback(true);
        EMIT_DATA();
        VIBRATE(this.vibrate);
        PLAY_AUDIO(this.isBeep);
      }
      // HTMLVideoElement
      if (element instanceof HTMLVideoElement) {
        EMIT_DATA();
        VIBRATE(this.vibrate);
        PLAY_AUDIO(this.isBeep);
      }
    } else {
      callback(false);
      this.removeCanvas();
      this.dataForResize = code;
    }
  }

  /**
   * removeCanvas
   */
  private removeCanvas(): void {
    Object.assign([], this.resultsPanel.nativeElement.childNodes).forEach(el => this.resultsPanel.nativeElement.removeChild(el));
  }

  /**
   * status
   * @param isStart 
   * @param isLoading 
   */
  private status(isStart: boolean, isLoading: boolean): void {
    this.isStart = isStart;
    this.isLoading = isLoading;
  }

  /**
   * eventEmit
   * @param response 
   */
  private eventEmit(response: any = false): void {
    (response !== false) && this.data.next(response || { data: null });
    (response !== false) && this.event.emit(response || { data: null });
  }

  /**
   * Single-thread
   * Loop Recording on Camera
   * Must be destroy request 
   * Not using: requestAnimationFrame
   */
  private requestAnimationFrame(): void {
    this.rAF_ID = setInterval(() => {
      if (this.video.nativeElement.readyState === this.video.nativeElement.HAVE_ENOUGH_DATA) {
        this.drawImage(this.video.nativeElement);
      }
    }, this.fps);
  }

  /**
   * Status of camera
   * @return boolean
   */
  get isPause(): boolean {
    return this.isStart && this.video.nativeElement.paused;
  }

  ngOnDestroy(): void {
    this.pause();
  }
}