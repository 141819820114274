export const EMOJIS = [
  {
    emojis: [
      ['ð', 'grinning'],
      ['ð', 'smiley'],
      ['ð', 'smile'],
      ['ð', 'grin'],
      ['ð', 'laughing'],
      ['ð', 'sweat_smile'],
      ['ð', 'joy'],
      ['ð¤£', 'rofl'],
      ['ð', 'blush'],
      ['ð', 'innocent'],
      ['ð', 'slightly_smiling_face'],
      ['ð', 'upside_down_face'],
      ['ð', 'wink'],
      ['ð', 'relieved'],
      ['ð', 'heart_eyes'],
      ['ð', 'kissing_heart'],
      ['ð', 'kissing'],
      ['ð', 'kissing_smiling_eyes'],
      ['ð', 'kissing_closed_eyes'],
      ['ð', 'yum'],
      ['ð', 'stuck_out_tongue_winking_eye'],
      ['ð', 'stuck_out_tongue_closed_eyes'],
      ['ð', 'stuck_out_tongue'],
      ['ð¤', 'money_mouth_face'],
      ['ð¤', 'hugs'],
      ['ð¤', 'nerd_face'],
      ['ð', 'sunglasses'],
      ['ð¤¡', 'clown_face'],
      ['ð¤ ', 'cowboy_hat_face'],
      ['ð', 'smirk'],
      ['ð', 'unamused'],
      ['ð', 'disappointed'],
      ['ð', 'pensive'],
      ['ð', 'worried'],
      ['ð', 'confused'],
      ['ð', 'slightly_frowning_face'],
      ['â¹ï¸', 'frowning_face'],
      ['ð£', 'persevere'],
      ['ð', 'confounded'],
      ['ð«', 'tired_face'],
      ['ð©', 'weary'],
      ['ð¤', 'triumph'],
      ['ð ', 'angry'],
      ['ð¡', 'rage'],
      ['ð¶', 'no_mouth'],
      ['ð', 'neutral_face'],
      ['ð', 'expressionless'],
      ['ð¯', 'hushed'],
      ['ð¦', 'frowning'],
      ['ð§', 'anguished'],
      ['ð®', 'open_mouth'],
      ['ð²', 'astonished'],
      ['ðµ', 'dizzy_face'],
      ['ð³', 'flushed'],
      ['ð±', 'scream'],
      ['ð¨', 'fearful'],
      ['ð°', 'cold_sweat'],
      ['ð¢', 'cry'],
      ['ð¥', 'disappointed_relieved'],
      ['ð¤¤', 'drooling_face'],
      ['ð­', 'sob'],
      ['ð', 'sweat'],
      ['ðª', 'sleepy'],
      ['ð´', 'sleeping'],
      ['ð', 'roll_eyes'],
      ['ð¤', 'thinking'],
      ['ð¤¥', 'lying_face'],
      ['ð¬', 'grimacing'],
      ['ð¤', 'zipper_mouth_face'],
      ['ð¤¢', 'nauseated_face'],
      ['ð¤§', 'sneezing_face'],
      ['ð·', 'mask'],
      ['ð¤', 'face_with_thermometer'],
      ['ð¤', 'face_with_head_bandage'],
      ['ð', 'smiling_imp'],
      ['ð¿', 'imp'],
      ['ð¹', 'japanese_ogre'],
      ['ðº', 'japanese_goblin'],
      ['ð©', 'hankey'],
      ['ð»', 'ghost'],
      ['ð', 'skull'],
      ['â ï¸', 'skull_and_crossbones'],
      ['ð½', 'alien'],
      ['ð¾', 'space_invader'],
      ['ð¤', 'robot'],
      ['ð', 'jack_o_lantern'],
      ['ðº', 'smiley_cat'],
      ['ð¸', 'smile_cat'],
      ['ð¹', 'joy_cat'],
      ['ð»', 'heart_eyes_cat'],
      ['ð¼', 'smirk_cat'],
      ['ð½', 'kissing_cat'],
      ['ð', 'scream_cat'],
      ['ð¿', 'crying_cat_face'],
      ['ð¾', 'pouting_cat'],
      ['ð', 'open_hands'],
      ['ð', 'raised_hands'],
      ['ð', 'clap'],
      ['ð', 'pray'],
      ['ð¤', 'handshake'],
      ['ð', '+1'],
      ['ð', '-1'],
      ['ð', 'fist_oncoming'],
      ['â', 'fist_raised'],
      ['ð¤', 'fist_left'],
      ['ð¤', 'fist_right'],
      ['ð¤', 'crossed_fingers'],
      ['âï¸', 'v'],
      ['ð¤', 'metal'],
      ['ð', 'ok_hand'],
      ['ð', 'point_left'],
      ['ð', 'point_right'],
      ['ð', 'point_up_2'],
      ['ð', 'point_down'],
      ['âï¸', 'point_up'],
      ['â', 'hand'],
      ['ð¤', 'raised_back_of_hand'],
      ['ð', 'raised_hand_with_fingers_splayed'],
      ['ð', 'vulcan_salute'],
      ['ð', 'wave'],
      ['ð¤', 'call_me_hand'],
      ['ðª', 'muscle'],
      ['ð', 'middle_finger'],
      ['âï¸', 'writing_hand'],
      ['ð¤³', 'selfie'],
      ['ð', 'nail_care'],
      ['ð', 'ring'],
      ['ð', 'lipstick'],
      ['ð', 'kiss'],
      ['ð', 'lips'],
      ['ð', 'tongue'],
      ['ð', 'ear'],
      ['ð', 'nose'],
      ['ð£', 'footprints'],
      ['ð', 'eye'],
      ['ð', 'eyes'],
      ['ð£', 'speaking_head'],
      ['ð¤', 'bust_in_silhouette'],
      ['ð¥', 'busts_in_silhouette'],
      ['ð¶', 'baby'],
      ['ð¦', 'boy'],
      ['ð§', 'girl'],
      ['ð¨', 'man'],
      ['ð©', 'woman'],
      ['ð±ââ', 'blonde_woman'],
      ['ð±', 'blonde_man'],
      ['ð´', 'older_man'],
      ['ðµ', 'older_woman'],
      ['ð²', 'man_with_gua_pi_mao'],
      ['ð³ââ', 'woman_with_turban'],
      ['ð³', 'man_with_turban'],
      ['ð®ââ', 'policewoman'],
      ['ð®', 'policeman'],
      ['ð·ââ', 'construction_worker_woman'],
      ['ð·', 'construction_worker_man'],
      ['ðââ', 'guardswoman'],
      ['ð', 'guardsman'],
      ['ðµï¸ââï¸', 'female_detective'],
      ['ðµ', 'male_detective'],
      ['ð©ââ', 'woman_health_worker'],
      ['ð¨ââ', 'man_health_worker'],
      ['ð©âð¾', 'woman_farmer'],
      ['ð¨âð¾', 'man_farmer'],
      ['ð©âð³', 'woman_cook'],
      ['ð¨âð³', 'man_cook'],
      ['ð©âð', 'woman_student'],
      ['ð¨âð', 'man_student'],
      ['ð©âð¤', 'woman_singer'],
      ['ð¨âð¤', 'man_singer'],
      ['ð©âð«', 'woman_teacher'],
      ['ð¨âð«', 'man_teacher'],
      ['ð©âð­', 'woman_factory_worker'],
      ['ð¨âð­', 'man_factory_worker'],
      ['ð©âð»', 'woman_technologist'],
      ['ð¨âð»', 'man_technologist'],
      ['ð©âð¼', 'woman_office_worker'],
      ['ð¨âð¼', 'man_office_worker'],
      ['ð©âð§', 'woman_mechanic'],
      ['ð¨âð§', 'man_mechanic'],
      ['ð©âð¬', 'woman_scientist'],
      ['ð¨âð¬', 'man_scientist'],
      ['ð©âð¨', 'woman_artist'],
      ['ð¨âð¨', 'man_artist'],
      ['ð©âð', 'woman_firefighter'],
      ['ð¨âð', 'man_firefighter'],
      ['ð©ââ', 'woman_pilot'],
      ['ð¨ââ', 'man_pilot'],
      ['ð©âð', 'woman_astronaut'],
      ['ð¨âð', 'man_astronaut'],
      ['ð©ââ', 'woman_judge'],
      ['ð¨ââ', 'man_judge'],
      ['ð¤¶', 'mrs_claus'],
      ['ð', 'santa'],
      ['ð¸', 'princess'],
      ['ð¤´', 'prince'],
      ['ð°', 'bride_with_veil'],
      ['ð¤µ', 'man_in_tuxedo'],
      ['ð¼', 'angel'],
      ['ð¤°', 'pregnant_woman'],
      ['ðââ', 'bowing_woman'],
      ['ð', 'bowing_man'],
      ['ð', 'tipping_hand_woman'],
      ['ðââ', 'tipping_hand_man'],
      ['ð', 'no_good_woman'],
      ['ðââ', 'no_good_man'],
      ['ð', 'ok_woman'],
      ['ðââ', 'ok_man'],
      ['ð', 'raising_hand_woman'],
      ['ðââ', 'raising_hand_man'],
      ['ð¤¦ââ', 'woman_facepalming'],
      ['ð¤¦ââ', 'man_facepalming'],
      ['ð¤·ââ', 'woman_shrugging'],
      ['ð¤·ââ', 'man_shrugging'],
      ['ð', 'pouting_woman'],
      ['ðââ', 'pouting_man'],
      ['ð', 'frowning_woman'],
      ['ðââ', 'frowning_man'],
      ['ð', 'haircut_woman'],
      ['ðââ', 'haircut_man'],
      ['ð', 'massage_woman'],
      ['ðââ', 'massage_man'],
      ['ð´', 'business_suit_levitating'],
      ['ð', 'dancer'],
      ['ðº', 'man_dancing'],
      ['ð¯', 'dancing_women'],
      ['ð¯ââ', 'dancing_men'],
      ['ð¶ââ', 'walking_woman'],
      ['ð¶', 'walking_man'],
      ['ðââ', 'running_woman'],
      ['ð', 'running_man'],
      ['ð«', 'couple'],
      ['ð­', 'two_women_holding_hands'],
      ['ð¬', 'two_men_holding_hands'],
      ['ð', 'couple_with_heart_woman_man'],
      ['ð©ââ¤ï¸âð©', 'couple_with_heart_woman_woman'],
      ['ð¨ââ¤ï¸âð¨', 'couple_with_heart_man_man'],
      ['ð', 'couplekiss_man_woman'],
      ['ð©ââ¤ï¸âðâð©', 'couplekiss_woman_woman'],
      ['ð¨ââ¤ï¸âðâð¨', 'couplekiss_man_man'],
      ['ðª', 'family_man_woman_boy'],
      ['ð¨âð©âð§', 'family_man_woman_girl'],
      ['ð¨âð©âð§âð¦', 'family_man_woman_girl_boy'],
      ['ð¨âð©âð¦âð¦', 'family_man_woman_boy_boy'],
      ['ð¨âð©âð§âð§', 'family_man_woman_girl_girl'],
      ['ð©âð©âð¦', 'family_woman_woman_boy'],
      ['ð©âð©âð§', 'family_woman_woman_girl'],
      ['ð©âð©âð§âð¦', 'family_woman_woman_girl_boy'],
      ['ð©âð©âð¦âð¦', 'family_woman_woman_boy_boy'],
      ['ð©âð©âð§âð§', 'family_woman_woman_girl_girl'],
      ['ð¨âð¨âð¦', 'family_man_man_boy'],
      ['ð¨âð¨âð§', 'family_man_man_girl'],
      ['ð¨âð¨âð§âð¦', 'family_man_man_girl_boy'],
      ['ð¨âð¨âð¦âð¦', 'family_man_man_boy_boy'],
      ['ð¨âð¨âð§âð§', 'family_man_man_girl_girl'],
      ['ð©âð¦', 'family_woman_boy'],
      ['ð©âð§', 'family_woman_girl'],
      ['ð©âð§âð¦', 'family_woman_girl_boy'],
      ['ð©âð¦âð¦', 'family_woman_boy_boy'],
      ['ð©âð§âð§', 'family_woman_girl_girl'],
      ['ð¨âð¦', 'family_man_boy'],
      ['ð¨âð§', 'family_man_girl'],
      ['ð¨âð§âð¦', 'family_man_girl_boy'],
      ['ð¨âð¦âð¦', 'family_man_boy_boy'],
      ['ð¨âð§âð§', 'family_man_girl_girl'],
      ['ð', 'womans_clothes'],
      ['ð', 'shirt'],
      ['ð', 'jeans'],
      ['ð', 'necktie'],
      ['ð', 'dress'],
      ['ð', 'bikini'],
      ['ð', 'kimono'],
      ['ð ', 'high_heel'],
      ['ð¡', 'sandal'],
      ['ð¢', 'boot'],
      ['ð', 'mans_shoe'],
      ['ð', 'athletic_shoe'],
      ['ð', 'womans_hat'],
      ['ð©', 'tophat'],
      ['ð', 'mortar_board'],
      ['ð', 'crown'],
      ['â', 'rescue_worker_helmet'],
      ['ð', 'school_satchel'],
      ['ð', 'pouch'],
      ['ð', 'purse'],
      ['ð', 'handbag'],
      ['ð¼', 'briefcase'],
      ['ð', 'eyeglasses'],
      ['ð¶', 'dark_sunglasses'],
      ['ð', 'closed_umbrella'],
      ['âï¸', 'open_umbrella']
    ],
    name: 'People',
    icon: ['ð', 'smile']
  },
  {
    emojis: [
      ['ð¶', 'dog'],
      ['ð±', 'cat'],
      ['ð­', 'mouse'],
      ['ð¹', 'hamster'],
      ['ð°', 'rabbit'],
      ['ð¦', 'fox_face'],
      ['ð»', 'bear'],
      ['ð¼', 'panda_face'],
      ['ð¨', 'koala'],
      ['ð¯', 'tiger'],
      ['ð¦', 'lion'],
      ['ð®', 'cow'],
      ['ð·', 'pig'],
      ['ð½', 'pig_nose'],
      ['ð¸', 'frog'],
      ['ðµ', 'monkey_face'],
      ['ð', 'see_no_evil'],
      ['ð', 'hear_no_evil'],
      ['ð', 'speak_no_evil'],
      ['ð', 'monkey'],
      ['ð', 'chicken'],
      ['ð§', 'penguin'],
      ['ð¦', 'bird'],
      ['ð¤', 'baby_chick'],
      ['ð£', 'hatching_chick'],
      ['ð¥', 'hatched_chick'],
      ['ð¦', 'duck'],
      ['ð¦', 'eagle'],
      ['ð¦', 'owl'],
      ['ð¦', 'bat'],
      ['ðº', 'wolf'],
      ['ð', 'boar'],
      ['ð´', 'horse'],
      ['ð¦', 'unicorn'],
      ['ð', 'bee'],
      ['ð', 'bug'],
      ['ð¦', 'butterfly'],
      ['ð', 'snail'],
      ['ð', 'shell'],
      ['ð', 'beetle'],
      ['ð', 'ant'],
      ['ð·', 'spider'],
      ['ð¸', 'spider_web'],
      ['ð¢', 'turtle'],
      ['ð', 'snake'],
      ['ð¦', 'lizard'],
      ['ð¦', 'scorpion'],
      ['ð¦', 'crab'],
      ['ð¦', 'squid'],
      ['ð', 'octopus'],
      ['ð¦', 'shrimp'],
      ['ð ', 'tropical_fish'],
      ['ð', 'fish'],
      ['ð¡', 'blowfish'],
      ['ð¬', 'dolphin'],
      ['ð¦', 'shark'],
      ['ð³', 'whale'],
      ['ð', 'whale2'],
      ['ð', 'crocodile'],
      ['ð', 'leopard'],
      ['ð', 'tiger2'],
      ['ð', 'water_buffalo'],
      ['ð', 'ox'],
      ['ð', 'cow2'],
      ['ð¦', 'deer'],
      ['ðª', 'dromedary_camel'],
      ['ð«', 'camel'],
      ['ð', 'elephant'],
      ['ð¦', 'rhinoceros'],
      ['ð¦', 'gorilla'],
      ['ð', 'racehorse'],
      ['ð', 'pig2'],
      ['ð', 'goat'],
      ['ð', 'ram'],
      ['ð', 'sheep'],
      ['ð', 'dog2'],
      ['ð©', 'poodle'],
      ['ð', 'cat2'],
      ['ð', 'rooster'],
      ['ð¦', 'turkey'],
      ['ð', 'dove'],
      ['ð', 'rabbit2'],
      ['ð', 'mouse2'],
      ['ð', 'rat'],
      ['ð¿', 'chipmunk'],
      ['ð¾', 'feet'],
      ['ð', 'dragon'],
      ['ð²', 'dragon_face'],
      ['ðµ', 'cactus'],
      ['ð', 'christmas_tree'],
      ['ð²', 'evergreen_tree'],
      ['ð³', 'deciduous_tree'],
      ['ð´', 'palm_tree'],
      ['ð±', 'seedling'],
      ['ð¿', 'herb'],
      ['âï¸', 'shamrock'],
      ['ð', 'four_leaf_clover'],
      ['ð', 'bamboo'],
      ['ð', 'tanabata_tree'],
      ['ð', 'leaves'],
      ['ð', 'fallen_leaf'],
      ['ð', 'maple_leaf'],
      ['ð', 'mushroom'],
      ['ð¾', 'ear_of_rice'],
      ['ð', 'bouquet'],
      ['ð·', 'tulip'],
      ['ð¹', 'rose'],
      ['ð¥', 'wilted_flower'],
      ['ð»', 'sunflower'],
      ['ð¼', 'blossom'],
      ['ð¸', 'cherry_blossom'],
      ['ðº', 'hibiscus'],
      ['ð', 'earth_americas'],
      ['ð', 'earth_africa'],
      ['ð', 'earth_asia'],
      ['ð', 'full_moon'],
      ['ð', 'waning_gibbous_moon'],
      ['ð', 'last_quarter_moon'],
      ['ð', 'waning_crescent_moon'],
      ['ð', 'new_moon'],
      ['ð', 'waxing_crescent_moon'],
      ['ð', 'first_quarter_moon'],
      ['ð', 'moon'],
      ['ð', 'new_moon_with_face'],
      ['ð', 'full_moon_with_face'],
      ['ð', 'sun_with_face'],
      ['ð', 'first_quarter_moon_with_face'],
      ['ð', 'last_quarter_moon_with_face'],
      ['ð', 'crescent_moon'],
      ['ð«', 'dizzy'],
      ['â­ï¸', 'star'],
      ['ð', 'star2'],
      ['â¨', 'sparkles'],
      ['â¡ï¸', 'zap'],
      ['ð¥', 'fire'],
      ['ð¥', 'boom'],
      ['â', 'comet'],
      ['âï¸', 'sunny'],
      ['ð¤', 'sun_behind_small_cloud'],
      ['âï¸', 'partly_sunny'],
      ['ð¥', 'sun_behind_large_cloud'],
      ['ð¦', 'sun_behind_rain_cloud'],
      ['ð', 'rainbow'],
      ['âï¸', 'cloud'],
      ['ð§', 'cloud_with_rain'],
      ['â', 'cloud_with_lightning_and_rain'],
      ['ð©', 'cloud_with_lightning'],
      ['ð¨', 'cloud_with_snow'],
      ['âï¸', 'snowman_with_snow'],
      ['âï¸', 'snowman'],
      ['âï¸', 'snowflake'],
      ['ð¬', 'wind_face'],
      ['ð¨', 'dash'],
      ['ðª', 'tornado'],
      ['ð«', 'fog'],
      ['ð', 'ocean'],
      ['ð§', 'droplet'],
      ['ð¦', 'sweat_drops'],
      ['âï¸', 'umbrella']
    ],
    name: 'Nature',
    icon: ['ð¸', 'cherry_blossom']
  },
  {
    emojis: [
      ['ð', 'green_apple'],
      ['ð', 'apple'],
      ['ð', 'pear'],
      ['ð', 'tangerine'],
      ['ð', 'lemon'],
      ['ð', 'banana'],
      ['ð', 'watermelon'],
      ['ð', 'grapes'],
      ['ð', 'strawberry'],
      ['ð', 'melon'],
      ['ð', 'cherries'],
      ['ð', 'peach'],
      ['ð', 'pineapple'],
      ['ð¥', 'kiwi_fruit'],
      ['ð¥', 'avocado'],
      ['ð', 'tomato'],
      ['ð', 'eggplant'],
      ['ð¥', 'cucumber'],
      ['ð¥', 'carrot'],
      ['ð½', 'corn'],
      ['ð¶', 'hot_pepper'],
      ['ð¥', 'potato'],
      ['ð ', 'sweet_potato'],
      ['ð°', 'chestnut'],
      ['ð¥', 'peanuts'],
      ['ð¯', 'honey_pot'],
      ['ð¥', 'croissant'],
      ['ð', 'bread'],
      ['ð¥', 'baguette_bread'],
      ['ð§', 'cheese'],
      ['ð¥', 'egg'],
      ['ð³', 'fried_egg'],
      ['ð¥', 'bacon'],
      ['ð¥', 'pancakes'],
      ['ð¤', 'fried_shrimp'],
      ['ð', 'poultry_leg'],
      ['ð', 'meat_on_bone'],
      ['ð', 'pizza'],
      ['ð­', 'hotdog'],
      ['ð', 'hamburger'],
      ['ð', 'fries'],
      ['ð¥', 'stuffed_flatbread'],
      ['ð®', 'taco'],
      ['ð¯', 'burrito'],
      ['ð¥', 'green_salad'],
      ['ð¥', 'shallow_pan_of_food'],
      ['ð', 'spaghetti'],
      ['ð', 'ramen'],
      ['ð²', 'stew'],
      ['ð¥', 'fish_cake'],
      ['ð£', 'sushi'],
      ['ð±', 'bento'],
      ['ð', 'curry'],
      ['ð', 'rice'],
      ['ð', 'rice_ball'],
      ['ð', 'rice_cracker'],
      ['ð¢', 'oden'],
      ['ð¡', 'dango'],
      ['ð§', 'shaved_ice'],
      ['ð¨', 'ice_cream'],
      ['ð¦', 'icecream'],
      ['ð°', 'cake'],
      ['ð', 'birthday'],
      ['ð®', 'custard'],
      ['ð­', 'lollipop'],
      ['ð¬', 'candy'],
      ['ð«', 'chocolate_bar'],
      ['ð¿', 'popcorn'],
      ['ð©', 'doughnut'],
      ['ðª', 'cookie'],
      ['ð¥', 'milk_glass'],
      ['ð¼', 'baby_bottle'],
      ['âï¸', 'coffee'],
      ['ðµ', 'tea'],
      ['ð¶', 'sake'],
      ['ðº', 'beer'],
      ['ð»', 'beers'],
      ['ð¥', 'clinking_glasses'],
      ['ð·', 'wine_glass'],
      ['ð¥', 'tumbler_glass'],
      ['ð¸', 'cocktail'],
      ['ð¹', 'tropical_drink'],
      ['ð¾', 'champagne'],
      ['ð¥', 'spoon'],
      ['ð´', 'fork_and_knife'],
      ['ð½', 'plate_with_cutlery']
    ],
    name: 'Foods',
    icon: ['ð', 'hamburger']
  },
  {
    emojis: [
      ['â½ï¸', 'soccer'],
      ['ð', 'basketball'],
      ['ð', 'football'],
      ['â¾ï¸', 'baseball'],
      ['ð¾', 'tennis'],
      ['ð', 'volleyball'],
      ['ð', 'rugby_football'],
      ['ð±', '8ball'],
      ['ð', 'ping_pong'],
      ['ð¸', 'badminton'],
      ['ð¥', 'goal_net'],
      ['ð', 'ice_hockey'],
      ['ð', 'field_hockey'],
      ['ð', 'cricket'],
      ['â³ï¸', 'golf'],
      ['ð¹', 'bow_and_arrow'],
      ['ð£', 'fishing_pole_and_fish'],
      ['ð¥', 'boxing_glove'],
      ['ð¥', 'martial_arts_uniform'],
      ['â¸', 'ice_skate'],
      ['ð¿', 'ski'],
      ['â·', 'skier'],
      ['ð', 'snowboarder'],
      ['ðï¸ââï¸', 'weight_lifting_woman'],
      ['ð', 'weight_lifting_man'],
      ['ð¤º', 'person_fencing'],
      ['ð¤¼ââ', 'women_wrestling'],
      ['ð¤¼ââ', 'men_wrestling'],
      ['ð¤¸ââ', 'woman_cartwheeling'],
      ['ð¤¸ââ', 'man_cartwheeling'],
      ['â¹ï¸ââï¸', 'basketball_woman'],
      ['â¹', 'basketball_man'],
      ['ð¤¾ââ', 'woman_playing_handball'],
      ['ð¤¾ââ', 'man_playing_handball'],
      ['ðï¸ââï¸', 'golfing_woman'],
      ['ð', 'golfing_man'],
      ['ðââ', 'surfing_woman'],
      ['ð', 'surfing_man'],
      ['ðââ', 'swimming_woman'],
      ['ð', 'swimming_man'],
      ['ð¤½ââ', 'woman_playing_water_polo'],
      ['ð¤½ââ', 'man_playing_water_polo'],
      ['ð£ââ', 'rowing_woman'],
      ['ð£', 'rowing_man'],
      ['ð', 'horse_racing'],
      ['ð´ââ', 'biking_woman'],
      ['ð´', 'biking_man'],
      ['ðµââ', 'mountain_biking_woman'],
      ['ðµ', 'mountain_biking_man'],
      ['ð½', 'running_shirt_with_sash'],
      ['ð', 'medal_sports'],
      ['ð', 'medal_military'],
      ['ð¥', '1st_place_medal'],
      ['ð¥', '2nd_place_medal'],
      ['ð¥', '3rd_place_medal'],
      ['ð', 'trophy'],
      ['ðµ', 'rosette'],
      ['ð', 'reminder_ribbon'],
      ['ð«', 'ticket'],
      ['ð', 'tickets'],
      ['ðª', 'circus_tent'],
      ['ð¤¹ââ', 'woman_juggling'],
      ['ð¤¹ââ', 'man_juggling'],
      ['ð­', 'performing_arts'],
      ['ð¨', 'art'],
      ['ð¬', 'clapper'],
      ['ð¤', 'microphone'],
      ['ð§', 'headphones'],
      ['ð¼', 'musical_score'],
      ['ð¹', 'musical_keyboard'],
      ['ð¥', 'drum'],
      ['ð·', 'saxophone'],
      ['ðº', 'trumpet'],
      ['ð¸', 'guitar'],
      ['ð»', 'violin'],
      ['ð²', 'game_die'],
      ['ð¯', 'dart'],
      ['ð³', 'bowling'],
      ['ð®', 'video_game'],
      ['ð°', 'slot_machine']
    ],
    name: 'Activity',
    icon: ['â½ï¸', 'soccer']
  },
  {
    emojis: [
      ['ð', 'car'],
      ['ð', 'taxi'],
      ['ð', 'blue_car'],
      ['ð', 'bus'],
      ['ð', 'trolleybus'],
      ['ð', 'racing_car'],
      ['ð', 'police_car'],
      ['ð', 'ambulance'],
      ['ð', 'fire_engine'],
      ['ð', 'minibus'],
      ['ð', 'truck'],
      ['ð', 'articulated_lorry'],
      ['ð', 'tractor'],
      ['ð´', 'kick_scooter'],
      ['ð²', 'bike'],
      ['ðµ', 'motor_scooter'],
      ['ð', 'motorcycle'],
      ['ð¨', 'rotating_light'],
      ['ð', 'oncoming_police_car'],
      ['ð', 'oncoming_bus'],
      ['ð', 'oncoming_automobile'],
      ['ð', 'oncoming_taxi'],
      ['ð¡', 'aerial_tramway'],
      ['ð ', 'mountain_cableway'],
      ['ð', 'suspension_railway'],
      ['ð', 'railway_car'],
      ['ð', 'train'],
      ['ð', 'mountain_railway'],
      ['ð', 'monorail'],
      ['ð', 'bullettrain_side'],
      ['ð', 'bullettrain_front'],
      ['ð', 'light_rail'],
      ['ð', 'steam_locomotive'],
      ['ð', 'train2'],
      ['ð', 'metro'],
      ['ð', 'tram'],
      ['ð', 'station'],
      ['ð', 'helicopter'],
      ['ð©', 'small_airplane'],
      ['âï¸', 'airplane'],
      ['ð«', 'flight_departure'],
      ['ð¬', 'flight_arrival'],
      ['ð', 'rocket'],
      ['ð°', 'artificial_satellite'],
      ['ðº', 'seat'],
      ['ð¶', 'canoe'],
      ['âµï¸', 'boat'],
      ['ð¥', 'motor_boat'],
      ['ð¤', 'speedboat'],
      ['ð³', 'passenger_ship'],
      ['â´', 'ferry'],
      ['ð¢', 'ship'],
      ['âï¸', 'anchor'],
      ['ð§', 'construction'],
      ['â½ï¸', 'fuelpump'],
      ['ð', 'busstop'],
      ['ð¦', 'vertical_traffic_light'],
      ['ð¥', 'traffic_light'],
      ['ðº', 'world_map'],
      ['ð¿', 'moyai'],
      ['ð½', 'statue_of_liberty'],
      ['â²ï¸', 'fountain'],
      ['ð¼', 'tokyo_tower'],
      ['ð°', 'european_castle'],
      ['ð¯', 'japanese_castle'],
      ['ð', 'stadium'],
      ['ð¡', 'ferris_wheel'],
      ['ð¢', 'roller_coaster'],
      ['ð ', 'carousel_horse'],
      ['â±', 'parasol_on_ground'],
      ['ð', 'beach_umbrella'],
      ['ð', 'desert_island'],
      ['â°', 'mountain'],
      ['ð', 'mountain_snow'],
      ['ð»', 'mount_fuji'],
      ['ð', 'volcano'],
      ['ð', 'desert'],
      ['ð', 'camping'],
      ['âºï¸', 'tent'],
      ['ð¤', 'railway_track'],
      ['ð£', 'motorway'],
      ['ð', 'building_construction'],
      ['ð­', 'factory'],
      ['ð ', 'house'],
      ['ð¡', 'house_with_garden'],
      ['ð', 'houses'],
      ['ð', 'derelict_house'],
      ['ð¢', 'office'],
      ['ð¬', 'department_store'],
      ['ð£', 'post_office'],
      ['ð¤', 'european_post_office'],
      ['ð¥', 'hospital'],
      ['ð¦', 'bank'],
      ['ð¨', 'hotel'],
      ['ðª', 'convenience_store'],
      ['ð«', 'school'],
      ['ð©', 'love_hotel'],
      ['ð', 'wedding'],
      ['ð', 'classical_building'],
      ['âªï¸', 'church'],
      ['ð', 'mosque'],
      ['ð', 'synagogue'],
      ['ð', 'kaaba'],
      ['â©', 'shinto_shrine'],
      ['ð¾', 'japan'],
      ['ð', 'rice_scene'],
      ['ð', 'national_park'],
      ['ð', 'sunrise'],
      ['ð', 'sunrise_over_mountains'],
      ['ð ', 'stars'],
      ['ð', 'sparkler'],
      ['ð', 'fireworks'],
      ['ð', 'city_sunrise'],
      ['ð', 'city_sunset'],
      ['ð', 'cityscape'],
      ['ð', 'night_with_stars'],
      ['ð', 'milky_way'],
      ['ð', 'bridge_at_night'],
      ['ð', 'foggy']
    ],
    name: 'Places',
    icon: ['ð', 'car']
  },
  {
    emojis: [
      ['âï¸', 'watch'],
      ['ð±', 'iphone'],
      ['ð²', 'calling'],
      ['ð»', 'computer'],
      ['â¨ï¸', 'keyboard'],
      ['ð¥', 'desktop_computer'],
      ['ð¨', 'printer'],
      ['ð±', 'computer_mouse'],
      ['ð²', 'trackball'],
      ['ð¹', 'joystick'],
      ['ð', 'clamp'],
      ['ð½', 'minidisc'],
      ['ð¾', 'floppy_disk'],
      ['ð¿', 'cd'],
      ['ð', 'dvd'],
      ['ð¼', 'vhs'],
      ['ð·', 'camera'],
      ['ð¸', 'camera_flash'],
      ['ð¹', 'video_camera'],
      ['ð¥', 'movie_camera'],
      ['ð½', 'film_projector'],
      ['ð', 'film_strip'],
      ['ð', 'telephone_receiver'],
      ['âï¸', 'phone'],
      ['ð', 'pager'],
      ['ð ', 'fax'],
      ['ðº', 'tv'],
      ['ð»', 'radio'],
      ['ð', 'studio_microphone'],
      ['ð', 'level_slider'],
      ['ð', 'control_knobs'],
      ['â±', 'stopwatch'],
      ['â²', 'timer_clock'],
      ['â°', 'alarm_clock'],
      ['ð°', 'mantelpiece_clock'],
      ['âï¸', 'hourglass'],
      ['â³', 'hourglass_flowing_sand'],
      ['ð¡', 'satellite'],
      ['ð', 'battery'],
      ['ð', 'electric_plug'],
      ['ð¡', 'bulb'],
      ['ð¦', 'flashlight'],
      ['ð¯', 'candle'],
      ['ð', 'wastebasket'],
      ['ð¢', 'oil_drum'],
      ['ð¸', 'money_with_wings'],
      ['ðµ', 'dollar'],
      ['ð´', 'yen'],
      ['ð¶', 'euro'],
      ['ð·', 'pound'],
      ['ð°', 'moneybag'],
      ['ð³', 'credit_card'],
      ['ð', 'gem'],
      ['âï¸', 'balance_scale'],
      ['ð§', 'wrench'],
      ['ð¨', 'hammer'],
      ['â', 'hammer_and_pick'],
      ['ð ', 'hammer_and_wrench'],
      ['â', 'pick'],
      ['ð©', 'nut_and_bolt'],
      ['âï¸', 'gear'],
      ['â', 'chains'],
      ['ð«', 'gun'],
      ['ð£', 'bomb'],
      ['ðª', 'hocho'],
      ['ð¡', 'dagger'],
      ['âï¸', 'crossed_swords'],
      ['ð¡', 'shield'],
      ['ð¬', 'smoking'],
      ['â°ï¸', 'coffin'],
      ['â±ï¸', 'funeral_urn'],
      ['ðº', 'amphora'],
      ['ð®', 'crystal_ball'],
      ['ð¿', 'prayer_beads'],
      ['ð', 'barber'],
      ['âï¸', 'alembic'],
      ['ð­', 'telescope'],
      ['ð¬', 'microscope'],
      ['ð³', 'hole'],
      ['ð', 'pill'],
      ['ð', 'syringe'],
      ['ð¡', 'thermometer'],
      ['ð½', 'toilet'],
      ['ð°', 'potable_water'],
      ['ð¿', 'shower'],
      ['ð', 'bathtub'],
      ['ð', 'bath'],
      ['ð', 'bellhop_bell'],
      ['ð', 'key'],
      ['ð', 'old_key'],
      ['ðª', 'door'],
      ['ð', 'couch_and_lamp'],
      ['ð', 'bed'],
      ['ð', 'sleeping_bed'],
      ['ð¼', 'framed_picture'],
      ['ð', 'shopping'],
      ['ð', 'shopping_cart'],
      ['ð', 'gift'],
      ['ð', 'balloon'],
      ['ð', 'flags'],
      ['ð', 'ribbon'],
      ['ð', 'confetti_ball'],
      ['ð', 'tada'],
      ['ð', 'dolls'],
      ['ð®', 'izakaya_lantern'],
      ['ð', 'wind_chime'],
      ['âï¸', 'email'],
      ['ð©', 'envelope_with_arrow'],
      ['ð¨', 'incoming_envelope'],
      ['ð§', 'e-mail'],
      ['ð', 'love_letter'],
      ['ð¥', 'inbox_tray'],
      ['ð¤', 'outbox_tray'],
      ['ð¦', 'package'],
      ['ð·', 'label'],
      ['ðª', 'mailbox_closed'],
      ['ð«', 'mailbox'],
      ['ð¬', 'mailbox_with_mail'],
      ['ð­', 'mailbox_with_no_mail'],
      ['ð®', 'postbox'],
      ['ð¯', 'postal_horn'],
      ['ð', 'scroll'],
      ['ð', 'page_with_curl'],
      ['ð', 'page_facing_up'],
      ['ð', 'bookmark_tabs'],
      ['ð', 'bar_chart'],
      ['ð', 'chart_with_upwards_trend'],
      ['ð', 'chart_with_downwards_trend'],
      ['ð', 'spiral_notepad'],
      ['ð', 'spiral_calendar'],
      ['ð', 'calendar'],
      ['ð', 'date'],
      ['ð', 'card_index'],
      ['ð', 'card_file_box'],
      ['ð³', 'ballot_box'],
      ['ð', 'file_cabinet'],
      ['ð', 'clipboard'],
      ['ð', 'file_folder'],
      ['ð', 'open_file_folder'],
      ['ð', 'card_index_dividers'],
      ['ð', 'newspaper_roll'],
      ['ð°', 'newspaper'],
      ['ð', 'notebook'],
      ['ð', 'notebook_with_decorative_cover'],
      ['ð', 'ledger'],
      ['ð', 'closed_book'],
      ['ð', 'green_book'],
      ['ð', 'blue_book'],
      ['ð', 'orange_book'],
      ['ð', 'books'],
      ['ð', 'book'],
      ['ð', 'bookmark'],
      ['ð', 'link'],
      ['ð', 'paperclip'],
      ['ð', 'paperclips'],
      ['ð', 'triangular_ruler'],
      ['ð', 'straight_ruler'],
      ['ð', 'pushpin'],
      ['ð', 'round_pushpin'],
      ['âï¸', 'scissors'],
      ['ð', 'pen'],
      ['ð', 'fountain_pen'],
      ['âï¸', 'black_nib'],
      ['ð', 'paintbrush'],
      ['ð', 'crayon'],
      ['ð', 'memo'],
      ['âï¸', 'pencil2'],
      ['ð', 'mag'],
      ['ð', 'mag_right'],
      ['ð', 'lock_with_ink_pen'],
      ['ð', 'closed_lock_with_key'],
      ['ð', 'lock'],
      ['ð', 'unlock']
    ],
    name: 'Objects',
    icon: ['ð', 'bell']
  },
  {
    emojis: [
      ['â¤ï¸', 'heart'],
      ['ð', 'yellow_heart'],
      ['ð', 'green_heart'],
      ['ð', 'blue_heart'],
      ['ð', 'purple_heart'],
      ['ð¤', 'black_heart'],
      ['ð', 'broken_heart'],
      ['â£ï¸', 'heavy_heart_exclamation'],
      ['ð', 'two_hearts'],
      ['ð', 'revolving_hearts'],
      ['ð', 'heartbeat'],
      ['ð', 'heartpulse'],
      ['ð', 'sparkling_heart'],
      ['ð', 'cupid'],
      ['ð', 'gift_heart'],
      ['ð', 'heart_decoration'],
      ['â®ï¸', 'peace_symbol'],
      ['âï¸', 'latin_cross'],
      ['âªï¸', 'star_and_crescent'],
      ['ð', 'om'],
      ['â¸ï¸', 'wheel_of_dharma'],
      ['â¡ï¸', 'star_of_david'],
      ['ð¯', 'six_pointed_star'],
      ['ð', 'menorah'],
      ['â¯ï¸', 'yin_yang'],
      ['â¦ï¸', 'orthodox_cross'],
      ['ð', 'place_of_worship'],
      ['â', 'ophiuchus'],
      ['âï¸', 'aries'],
      ['âï¸', 'taurus'],
      ['âï¸', 'gemini'],
      ['âï¸', 'cancer'],
      ['âï¸', 'leo'],
      ['âï¸', 'virgo'],
      ['âï¸', 'libra'],
      ['âï¸', 'scorpius'],
      ['âï¸', 'sagittarius'],
      ['âï¸', 'capricorn'],
      ['âï¸', 'aquarius'],
      ['âï¸', 'pisces'],
      ['ð', 'id'],
      ['âï¸', 'atom_symbol'],
      ['ð', 'accept'],
      ['â¢ï¸', 'radioactive'],
      ['â£ï¸', 'biohazard'],
      ['ð´', 'mobile_phone_off'],
      ['ð³', 'vibration_mode'],
      ['ð¶', 'u6709'],
      ['ðï¸', 'u7121'],
      ['ð¸', 'u7533'],
      ['ðº', 'u55b6'],
      ['ð·ï¸', 'u6708'],
      ['â´ï¸', 'eight_pointed_black_star'],
      ['ð', 'vs'],
      ['ð®', 'white_flower'],
      ['ð', 'ideograph_advantage'],
      ['ãï¸', 'secret'],
      ['ãï¸', 'congratulations'],
      ['ð´', 'u5408'],
      ['ðµ', 'u6e80'],
      ['ð¹', 'u5272'],
      ['ð²', 'u7981'],
      ['ð°ï¸', 'a'],
      ['ð±ï¸', 'b'],
      ['ð', 'ab'],
      ['ð', 'cl'],
      ['ð¾ï¸', 'o2'],
      ['ð', 'sos'],
      ['â', 'x'],
      ['â­ï¸', 'o'],
      ['ð', 'stop_sign'],
      ['âï¸', 'no_entry'],
      ['ð', 'name_badge'],
      ['ð«', 'no_entry_sign'],
      ['ð¯', '100'],
      ['ð¢', 'anger'],
      ['â¨ï¸', 'hotsprings'],
      ['ð·', 'no_pedestrians'],
      ['ð¯', 'do_not_litter'],
      ['ð³', 'no_bicycles'],
      ['ð±', 'non-potable_water'],
      ['ð', 'underage'],
      ['ðµ', 'no_mobile_phones'],
      ['ð­', 'no_smoking'],
      ['âï¸', 'exclamation'],
      ['â', 'grey_exclamation'],
      ['â', 'question'],
      ['â', 'grey_question'],
      ['â¼ï¸', 'bangbang'],
      ['âï¸', 'interrobang'],
      ['ð', 'low_brightness'],
      ['ð', 'high_brightness'],
      ['ã½ï¸', 'part_alternation_mark'],
      ['â ï¸', 'warning'],
      ['ð¸', 'children_crossing'],
      ['ð±', 'trident'],
      ['âï¸', 'fleur_de_lis'],
      ['ð°', 'beginner'],
      ['â»ï¸', 'recycle'],
      ['â', 'white_check_mark'],
      ['ð¯ï¸', 'u6307'],
      ['ð¹', 'chart'],
      ['âï¸', 'sparkle'],
      ['â³ï¸', 'eight_spoked_asterisk'],
      ['â', 'negative_squared_cross_mark'],
      ['ð', 'globe_with_meridians'],
      ['ð ', 'diamond_shape_with_a_dot_inside'],
      ['âï¸', 'm'],
      ['ð', 'cyclone'],
      ['ð¤', 'zzz'],
      ['ð§', 'atm'],
      ['ð¾', 'wc'],
      ['â¿ï¸', 'wheelchair'],
      ['ð¿ï¸', 'parking'],
      ['ð³', 'u7a7a'],
      ['ðï¸', 'sa'],
      ['ð', 'passport_control'],
      ['ð', 'customs'],
      ['ð', 'baggage_claim'],
      ['ð', 'left_luggage'],
      ['ð¹', 'mens'],
      ['ðº', 'womens'],
      ['ð¼', 'baby_symbol'],
      ['ð»', 'restroom'],
      ['ð®', 'put_litter_in_its_place'],
      ['ð¦', 'cinema'],
      ['ð¶', 'signal_strength'],
      ['ð', 'koko'],
      ['ð£', 'symbols'],
      ['â¹ï¸', 'information_source'],
      ['ð¤', 'abc'],
      ['ð¡', 'abcd'],
      ['ð ', 'capital_abcd'],
      ['ð', 'ng'],
      ['ð', 'ok'],
      ['ð', 'up'],
      ['ð', 'cool'],
      ['ð', 'new'],
      ['ð', 'free'],
      ['0ï¸â£', 'zero'],
      ['1ï¸â£', 'one'],
      ['2ï¸â£', 'two'],
      ['3ï¸â£', 'three'],
      ['4ï¸â£', 'four'],
      ['5ï¸â£', 'five'],
      ['6ï¸â£', 'six'],
      ['7ï¸â£', 'seven'],
      ['8ï¸â£', 'eight'],
      ['9ï¸â£', 'nine'],
      ['ð', 'keycap_ten'],
      ['ð¢', '1234'],
      ['#ï¸â£', 'hash'],
      ['*ï¸â£', 'asterisk'],
      ['â¶ï¸', 'arrow_forward'],
      ['â¸', 'pause_button'],
      ['â¯', 'play_or_pause_button'],
      ['â¹', 'stop_button'],
      ['âº', 'record_button'],
      ['â­', 'next_track_button'],
      ['â®', 'previous_track_button'],
      ['â©', 'fast_forward'],
      ['âª', 'rewind'],
      ['â«', 'arrow_double_up'],
      ['â¬', 'arrow_double_down'],
      ['âï¸', 'arrow_backward'],
      ['ð¼', 'arrow_up_small'],
      ['ð½', 'arrow_down_small'],
      ['â¡ï¸', 'arrow_right'],
      ['â¬ï¸', 'arrow_left'],
      ['â¬ï¸', 'arrow_up'],
      ['â¬ï¸', 'arrow_down'],
      ['âï¸', 'arrow_upper_right'],
      ['âï¸', 'arrow_lower_right'],
      ['âï¸', 'arrow_lower_left'],
      ['âï¸', 'arrow_upper_left'],
      ['âï¸', 'arrow_up_down'],
      ['âï¸', 'left_right_arrow'],
      ['âªï¸', 'arrow_right_hook'],
      ['â©ï¸', 'leftwards_arrow_with_hook'],
      ['â¤´ï¸', 'arrow_heading_up'],
      ['â¤µï¸', 'arrow_heading_down'],
      ['ð', 'twisted_rightwards_arrows'],
      ['ð', 'repeat'],
      ['ð', 'repeat_one'],
      ['ð', 'arrows_counterclockwise'],
      ['ð', 'arrows_clockwise'],
      ['ðµ', 'musical_note'],
      ['ð¶', 'notes'],
      ['â', 'heavy_plus_sign'],
      ['â', 'heavy_minus_sign'],
      ['â', 'heavy_division_sign'],
      ['âï¸', 'heavy_multiplication_x'],
      ['ð²', 'heavy_dollar_sign'],
      ['ð±', 'currency_exchange'],
      ['â¢ï¸', 'tm'],
      ['Â©ï¸', 'copyright'],
      ['Â®ï¸', 'registered'],
      ['ã°ï¸', 'wavy_dash'],
      ['â°', 'curly_loop'],
      ['â¿', 'loop'],
      ['ð', 'end'],
      ['ð', 'back'],
      ['ð', 'on'],
      ['ð', 'top'],
      ['ð', 'soon'],
      ['âï¸', 'heavy_check_mark'],
      ['âï¸', 'ballot_box_with_check'],
      ['ð', 'radio_button'],
      ['âªï¸', 'white_circle'],
      ['â«ï¸', 'black_circle'],
      ['ð´', 'red_circle'],
      ['ðµ', 'large_blue_circle'],
      ['ðº', 'small_red_triangle'],
      ['ð»', 'small_red_triangle_down'],
      ['ð¸', 'small_orange_diamond'],
      ['ð¹', 'small_blue_diamond'],
      ['ð¶', 'large_orange_diamond'],
      ['ð·', 'large_blue_diamond'],
      ['ð³', 'white_square_button'],
      ['ð²', 'black_square_button'],
      ['âªï¸', 'black_small_square'],
      ['â«ï¸', 'white_small_square'],
      ['â¾ï¸', 'black_medium_small_square'],
      ['â½ï¸', 'white_medium_small_square'],
      ['â¼ï¸', 'black_medium_square'],
      ['â»ï¸', 'white_medium_square'],
      ['â¬ï¸', 'black_large_square'],
      ['â¬ï¸', 'white_large_square'],
      ['ð', 'speaker'],
      ['ð', 'mute'],
      ['ð', 'sound'],
      ['ð', 'loud_sound'],
      ['ð', 'bell'],
      ['ð', 'no_bell'],
      ['ð£', 'mega'],
      ['ð¢', 'loudspeaker'],
      ['ðâð¨', 'eye_speech_bubble'],
      ['ð¬', 'speech_balloon'],
      ['ð­', 'thought_balloon'],
      ['ð¯', 'right_anger_bubble'],
      ['â ï¸', 'spades'],
      ['â£ï¸', 'clubs'],
      ['â¥ï¸', 'hearts'],
      ['â¦ï¸', 'diamonds'],
      ['ð', 'black_joker'],
      ['ð´', 'flower_playing_cards'],
      ['ðï¸', 'mahjong'],
      ['ð', 'clock1'],
      ['ð', 'clock2'],
      ['ð', 'clock3'],
      ['ð', 'clock4'],
      ['ð', 'clock5'],
      ['ð', 'clock6'],
      ['ð', 'clock7'],
      ['ð', 'clock8'],
      ['ð', 'clock9'],
      ['ð', 'clock10'],
      ['ð', 'clock11'],
      ['ð', 'clock12'],
      ['ð', 'clock130'],
      ['ð', 'clock230'],
      ['ð', 'clock330'],
      ['ð', 'clock430'],
      ['ð ', 'clock530'],
      ['ð¡', 'clock630'],
      ['ð¢', 'clock730'],
      ['ð£', 'clock830'],
      ['ð¤', 'clock930'],
      ['ð¥', 'clock1030'],
      ['ð¦', 'clock1130'],
      ['ð§', 'clock1230']
    ],
    name: 'Symbols',
    icon: ['ð ', 'capital_abcd']
  }
]
