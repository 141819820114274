import { ScannerQRCodeConfig } from "./ngx-scanner-qrcode.options";

export const MEDIA_STREAM_DEFAULT: MediaStreamConstraints = {
  audio: false,
  video: true
};

export const CONFIG_DEFAULT: ScannerQRCodeConfig = {
  src: '',
  fps: 30,
  vibrate: 300,
  decode: 'utf-8',
  isBeep: true,
  deviceActive: 0,
  constraints: MEDIA_STREAM_DEFAULT,
};