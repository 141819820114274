export interface ScannerQRCodeConfig {
  src?: string;
  fps?: number;
  vibrate?: number; /** support mobile */
  decode?: string;
  isBeep?: boolean;
  deviceActive?: number;
  constraints?: MediaStreamConstraints | any;
}

export interface ScannerQRCodeSelectedFiles {
  url: string;
  name: string;
  file: File;
  data?: ScannerQRCodeResult[];
  canvas?: HTMLCanvasElement;
}

export interface ScannerQRCodeDevice {
  kind: string;
  label: string;
  groupId: string;
  deviceId: string;
}

export interface ScannerQRCodePoint {
  x: number;
  y: number;
}

export enum ScannerQRCodeSymbolType {
  ScannerQRCode_NONE = 0,   /**< no symbol decoded */
  ScannerQRCode_PARTIAL = 1,   /**< intermediate status */
  ScannerQRCode_EAN2 = 2,   /**< GS1 2-digit add-on */
  ScannerQRCode_EAN5 = 5,   /**< GS1 5-digit add-on */
  ScannerQRCode_EAN8 = 8,   /**< EAN-8 */
  ScannerQRCode_UPCE = 9,   /**< UPC-E */
  ScannerQRCode_ISBN10 = 10,  /**< ISBN-10 (from EAN-13). @since 0.4 */
  ScannerQRCode_UPCA = 12,  /**< UPC-A */
  ScannerQRCode_EAN13 = 13,  /**< EAN-13 */
  ScannerQRCode_ISBN13 = 14,  /**< ISBN-13 (from EAN-13). @since 0.4 */
  ScannerQRCode_COMPOSITE = 15,  /**< EAN/UPC composite */
  ScannerQRCode_I25 = 25,  /**< Interleaved 2 of 5. @since 0.4 */
  ScannerQRCode_DATABAR = 34,  /**< GS1 DataBar (RSS). @since 0.11 */
  ScannerQRCode_DATABAR_EXP = 35,  /**< GS1 DataBar Expanded. @since 0.11 */
  ScannerQRCode_CODABAR = 38,  /**< Codabar. @since 0.11 */
  ScannerQRCode_CODE39 = 39,  /**< Code 39. @since 0.4 */
  ScannerQRCode_PDF417 = 57,  /**< PDF417. @since 0.6 */
  ScannerQRCode_QRCODE = 64,  /**< QR Code. @since 0.10 */
  ScannerQRCode_SQCODE = 80,  /**< SQ Code. @since 0.20.1 */
  ScannerQRCode_CODE93 = 93,  /**< Code 93. @since 0.11 */
  ScannerQRCode_CODE128 = 128, /**< Code 128 */

  /*
   * Please see _ScannerQRCode_get_symbol_hash() if adding
   * anything after 128
   */

  /** mask for base symbol type.
   * @deprecated in 0.11, remove this from existing code
   */
  ScannerQRCode_SYMBOL = 0x00ff,
  /** 2-digit add-on flag.
   * @deprecated in 0.11, a ::ScannerQRCode_EAN2 component is used for
   * 2-digit GS1 add-ons
   */
  ScannerQRCode_ADDON2 = 0x0200,
  /** 5-digit add-on flag.
   * @deprecated in 0.11, a ::ScannerQRCode_EAN5 component is used for
   * 5-digit GS1 add-ons
   */
  ScannerQRCode_ADDON5 = 0x0500,
  /** add-on flag mask.
   * @deprecated in 0.11, GS1 add-ons are represented using composite
   * symbols of type ::ScannerQRCode_COMPOSITE; add-on components use ::ScannerQRCode_EAN2
   * or ::ScannerQRCode_EAN5
   */
  ScannerQRCode_ADDON = 0x0700,
}

export enum ScannerQRCodeConfigType {
  ScannerQRCode_CFG_ENABLE = 0,            /**< enable symbology/feature */
  ScannerQRCode_CFG_ADD_CHECK,        /**< enable check digit when optional */
  ScannerQRCode_CFG_EMIT_CHECK,       /**< return check digit when present */
  ScannerQRCode_CFG_ASCII,            /**< enable full ASCII character set */
  ScannerQRCode_CFG_BINARY,           /**< don't convert binary data to text */
  ScannerQRCode_CFG_NUM,              /**< number of boolean decoder configs */

  ScannerQRCode_CFG_MIN_LEN = 0x20,        /**< minimum data length for valid decode */
  ScannerQRCode_CFG_MAX_LEN,        /**< maximum data length for valid decode */

  ScannerQRCode_CFG_UNCERTAINTY = 0x40,    /**< required video consistency frames */

  ScannerQRCode_CFG_POSITION = 0x80,       /**< enable scanner to collect position data */
  ScannerQRCode_CFG_TEST_INVERTED,  /**< if fails to decode, test inverted */

  ScannerQRCode_CFG_X_DENSITY = 0x100,     /**< image scanner vertical scan density */
  ScannerQRCode_CFG_Y_DENSITY,     /**< image scanner horizontal scan density */
}

export enum ScannerQRCodeOrientation {
  ScannerQRCode_ORIENT_UNKNOWN = -1,       /**< unable to determine orientation */
  ScannerQRCode_ORIENT_UP,            /**< upright, read left to right */
  ScannerQRCode_ORIENT_RIGHT,         /**< sideways, read top to bottom */
  ScannerQRCode_ORIENT_DOWN,          /**< upside-down, read right to left */
  ScannerQRCode_ORIENT_LEFT,          /**< sideways, read bottom to top */
}

class ScannerQRCodeTypePointer {
  protected ptr: number;
  protected ptr32: number;
  protected buf: ArrayBuffer;
  protected HEAP8: Int8Array;
  protected HEAP32: Int32Array;
  protected HEAPU32: Uint32Array;

  constructor(ptr: number, buf: ArrayBuffer) {
    this.ptr = ptr;
    this.ptr32 = ptr >> 2;
    this.buf = buf;
    this.HEAP8 = new Int8Array(buf);
    this.HEAPU32 = new Uint32Array(buf);
    this.HEAP32 = new Int32Array(buf);
  }
}

class ScannerQRCodeSymbolPtr extends ScannerQRCodeTypePointer {
  get type(): ScannerQRCodeSymbolType {
    return this.HEAPU32[this.ptr32] as ScannerQRCodeSymbolType;
  }

  get data(): Int8Array {
    const len = this.HEAPU32[this.ptr32 + 4];
    const ptr = this.HEAPU32[this.ptr32 + 5];
    return Int8Array.from(this.HEAP8.subarray(ptr, ptr + len));
  }

  get points(): Array<ScannerQRCodePoint> {
    const len = this.HEAPU32[this.ptr32 + 7];
    const ptr = this.HEAPU32[this.ptr32 + 8];
    const ptr32 = ptr >> 2;
    const res: ScannerQRCodePoint[] = [];
    for (let i = 0; i < len; ++i) {
      const x = this.HEAP32[ptr32 + i * 2];
      const y = this.HEAP32[ptr32 + i * 2 + 1];
      res.push({ x, y } as ScannerQRCodePoint);
    }
    return res;
  }

  get orientation(): ScannerQRCodeOrientation {
    return this.HEAP32[this.ptr32 + 9];
  }

  get next(): ScannerQRCodeSymbolPtr | null {
    const ptr = this.HEAPU32[this.ptr32 + 11];
    if (!ptr) return null;
    return new ScannerQRCodeSymbolPtr(ptr, this.buf);
  }

  get time(): number {
    return this.HEAPU32[this.ptr32 + 13];
  }

  get cacheCount(): number {
    return this.HEAP32[this.ptr32 + 14];
  }

  get quality(): number {
    return this.HEAP32[this.ptr32 + 15];
  }
}

class SymbolSetPtr extends ScannerQRCodeTypePointer {
  get head(): ScannerQRCodeSymbolPtr | null {
    const ptr = this.HEAPU32[this.ptr32 + 2];
    if (!ptr) return null;
    return new ScannerQRCodeSymbolPtr(ptr, this.buf);
  }
}

export class ScannerQRCodeResult {
  type: ScannerQRCodeSymbolType;
  typeName: string;
  data: Int8Array;
  points: Array<ScannerQRCodePoint>;
  orientation: ScannerQRCodeOrientation;
  time: number;
  cacheCount: number;
  quality: number;
  value: string = '';

  private constructor(ptr: ScannerQRCodeSymbolPtr) {
    this.type = ptr.type;
    this.typeName = ScannerQRCodeSymbolType[this.type];
    this.data = ptr.data;
    this.points = ptr.points;
    this.orientation = ptr.orientation;
    this.time = ptr.time;
    this.cacheCount = ptr.cacheCount;
    this.quality = ptr.quality;
  }

  static createSymbolsFromPtr(ptr: number, buf: ArrayBuffer): Array<ScannerQRCodeResult> {
    if (ptr == 0) return [];

    const set = new SymbolSetPtr(ptr, buf);
    let symbol = set.head;
    const res: ScannerQRCodeResult[] = [];
    while (symbol !== null) {
      res.push(new ScannerQRCodeResult(symbol));
      symbol = symbol.next;
    }
    return res;
  }

  decode(encoding?: string) {
    const decoder = new TextDecoder(encoding);
    return decoder.decode(this.data);
  }
}